<template>
  <div :class="[isMobile ? 'mobile-about-report-pdf' : '', 'about-report-pdf']">
    <div class="report-content">
      <div class="pdf-title">{{ yearReportDetail.name }}</div>
      <div class="pdf-time">
        <span class="time">发布时间：{{ yearReportDetail.disploy_at && yearReportDetail.disploy_at.substring(0,10) }} | 来源：{{ yearReportDetail.source }}</span>
        <span class="report-download" @click="onDownload">下载报告</span>
      </div>
      <iframe :src="'./pdf/web/viewer.html?file='+ yearReportDetail.detail" frameborder="0" class="pdf_box"></iframe>
      <div class="page">
        <div class="btn-back" @click="goBack">返回列表</div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile, fileLink2Download, isWXWebview } from "@/helper/utils";
import { getNewsAndDutyDetail } from '@/api/aboutUs'
export default {
  name: 'reportPdf',
  data() {
    return {
      isMobile: isMobile(),
      yearReportDetail: {},
    };
  },
  activated() {
    this.getReportContent()
  },
  methods: {
    getReportContent() {
      getNewsAndDutyDetail({ id: this.$route.query.id }).then(res => {
        this.yearReportDetail = res
        this.$emit('loading', false)
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    // 点击 下载
    onDownload(){
      if(isWXWebview()){
        window.location.href = this.yearReportDetail.detail + '?downtype=view'
      }else{
        fileLink2Download(this.yearReportDetail.detail, this.yearReportDetail.name, 'pdf')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
:v-deep #toolbarViewer {
  height: 22px;
}

.about-report-pdf {
  margin-left: 34px;
  .report-content {
    padding-top: 0;
    text-align: center;
    .page {
      display: flex;
      justify-content: flex-end;
      padding: 0.4rem 0 0;
      border-top: 1px solid #dcdcdc;
      margin-top: 0.6rem;
      .btn-back {
        width: 1.4rem;
        height: 0.44rem;
        border: 1px solid #d0d0d0;
        border-radius: 0.22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.16rem;
        color: #666666;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #ee5400;
          border-color: #ee5400;
          color: #ffffff;
        }
      }
    }
    .pdf-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.36rem;
      color: #333333;
      line-height: 20px;
      font-style: normal;
      margin-top: 22px;
      margin-bottom: 6px;
    }
    .pdf-time {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.16rem;
      color: #666666;
      font-style: normal;
      margin-bottom: 11px;
      margin-top: 0.2rem;
      padding-bottom: 0.4rem;
      display: flex;
      align-items: center;
      .time{
        flex: 1;
        text-align: center;
        padding-left: 0.46rem;
      }
      .report-download{
        padding-right:0.24rem;
        margin:0;
      }
    }
  }
  .report-download{
    font-weight:600;
    font-size: 0.14rem;
    color: #2D75E7;
    position: relative;
    padding-left: 0.18rem;
    margin-left: 0.16rem;
    cursor: pointer;
    &:before{
      content: '';
      position: absolute;
      width: 0.14rem;
      height: 0.12rem;
      background: url('~@/assets/img/aboutus/download.png');
      background-size: 100% 100%;
      left: 0;
      top: 0.05rem;
    }
  }
  .pdf_box {
    min-height: 365px;
    height: 40vw;
    width: 100%;
  }
}

.mobile-about-report {
  margin-left: 0;
}
</style>
